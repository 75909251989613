import CryptoJS from 'crypto-js';



export const EncryptData = (inputString) => {
    var key = CryptoJS.enc.Utf8.parse("8bd930b3852b360a");
    var iv = CryptoJS.lib.WordArray.random(16);

    var cipherText = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(inputString),
        key,
        {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7 
        }
    );

    var encrypted = iv.concat(cipherText.ciphertext);
    return encrypted.toString(CryptoJS.enc.Base64);
};