import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import { useData } from '../../../../contexts/DataContext';
import { getUserOnMyLocations } from '../../../../services/viewsServices/requestUserOnMyLocationsService.js';
import { deleteUserDataService } from '../../../../services/CRUD/UserDataService';
import { useHasPrivileges  } from '../../../../contexts/DataContext';

const UserReview = () => {
  const { user } = useAuth();
  const { state } = useData();
  const { userData } = state;
  const [machines, setMachines] = useState([]);
  const navigate = useNavigate();
  const hasPrivileges = useHasPrivileges();

  const fetchData = async () => {
    try {
      const response = await getUserOnMyLocations(userData.username); // Llama a la función para obtener las máquinas basadas en las ubicaciones del usuario
      if (response.Success) {
        const data = response.Success;
        setMachines(data);
        console.log("Fetched data: ", data);
      } else {
        console.error('Respuesta de la API no tiene la propiedad "Success":', response);
      }
    } catch (error) {
      console.error('Error durante la solicitud:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleDelete = async (id) => {
    try {
      const result = await deleteUserDataService(id);

      if (result.success) {
        console.log('Usuario eliminado:', result.message);
        fetchData();
      } else {
        console.error('Error al eliminar el usuario:', result.message);
      }
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
    }
  };

  return (
    <div className='white-container size-big' style={{ width: '90%', overflow: 'auto'}}>
      <h2>Vista de Usuarios</h2>
      <table style={{overflow: 'auto'}}>
        <thead>
          <tr>
            <th style={{ width: '1%' }}>Nº</th>
            <th style={{ width: '10%' }}>Usuario</th>
            <th style={{ width: '10%' }}>Tipo Usuario</th>
            {hasPrivileges(['UPDATE MACHINE']) && (  <th style={{ width: '3%' }}>Acciones</th> )}
          </tr>
        </thead>
        <tbody >
          {Array.isArray(machines) && hasPrivileges(['READ USER']) && machines.length > 0 && machines.map((user, index) => (
            <tr key={user.ID} style={{ height: '50px'}} className={`text-center form-margin ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
              <td>{user.ID}</td>
              <td>{user.USERNAME}</td>
              <td>{user.ROLE_ID}</td>
              {hasPrivileges(['UPDATE USER']) && (
              <td>
                {hasPrivileges(['UPDATE USER']) && ( <button className='stylish-button blue' onClick={() => handleNavigation(`/views/users-review/edit-user/${user.ID}`)}>Editar</button> )}
                {hasPrivileges(['DELETE USER']) && ( <button className='stylish-button red' onClick={() => handleDelete(user.ID)}>Eliminar</button> )}
              </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );  
};

export default UserReview;
