import { BASE_URL } from './apiConfig';

const getAllCountries = async () => {
    try {
      const url = `${BASE_URL}/get_countries.php`;
      const response = await fetch(url);
  
      if (response.ok) {
        const data = await response.json();
  
        if ('Error' in data) {
          console.error('Error fetching countries:', data.Error);
          return { error: data.Error };
        } else {
          console.log('Contries fetched successfully:', data);
          return { success: data };
        }
      } else {
        console.error('Failed to fetch countries');
        return { error: 'Failed to fetch countries' };
      }
    } catch (error) {
      console.error('Network error:', error);
      return { error: 'Network error' };
    }
  };
  
  export { getAllCountries };
  