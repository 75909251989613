import React from 'react';
import { useNavigation } from '../../../services/navigationService.js';

const HomePage = () => {
  const { handleNavigation } = useNavigation();

  return (
    <>
    </>
  );
};

export default HomePage;
