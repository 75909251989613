import React, { useState, useRef, useEffect } from 'react';
import { useData } from '../../../contexts/DataContext';
import logo from '../../../assets/logo/tatan-logo.png'; 
import { useNavigate } from 'react-router-dom';
import { verifyEmail, attemptPassChange } from '../../../services/forgotPassService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../Loader/Loader';

import '../../../styles/misc/Forms.css';
import '../../../styles/MovingBackground.css';

const MAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const OTP_REGEX = /^[A-Za-z0-9]{6}$/;

const ForgotPasswordForm = () => {

  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [matchPwd, setMatchPwd] = useState('');
  const [optCode, setOtpCode] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [canResend, setCanResend] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // When OTP is sent, start the countdown
    if (otpSent && !canResend) {
      if (timeLeft > 0) {
        const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
        return () => clearTimeout(timer);
      } else {
        setCanResend(true);
      }
    }
  }, [otpSent, timeLeft, canResend]);

  const handleEmail = async (e) => {
    e.preventDefault();

    if(!isLoading){
      const v1 = MAIL_REGEX.test(email);
      if (!v1) {
          setErrMsg("Formato de email inválido.");
          errRef?.current?.focus();
          return;
      }
      setErrMsg('');
      setIsLoading(true);
      const { success, error } = await verifyEmail(email);
  
      if (success) {
        setOtpSent(true);
      } else {
        setErrMsg(error);
        errRef?.current?.focus();
      }
      setIsLoading(false);
    }
  };

  const handlePassChange = async (e) => {
    e.preventDefault();

    const v1 = MAIL_REGEX.test(email);
    const v2 = OTP_REGEX.test(optCode);
    if (!v1 || !v2) {
      setErrMsg("Formato de email o código inválido.");
      errRef?.current?.focus();
      return;
    }
    if(password !== matchPwd){
      setErrMsg("Contraseñas no coinciden");
      errRef?.current?.focus();
      return;
    }

    setErrMsg('');
    setIsLoading(true);
    const { success, error } = await attemptPassChange(email, optCode, password);
    if (success) {
      setChangeSuccess(true);
      setOtpCode('');
      setPassword('');
      setMatchPwd('');
      setEmail('');
    } else {
      setErrMsg(error);
      errRef?.current?.focus();
    }
    setIsLoading(false);
  }

  const handleOtpResend = async () => {

    if (canResend) {

      const v1 = MAIL_REGEX.test(email);
      if (!v1) {
          setErrMsg("Formato de email inválido.");
          errRef?.current?.focus();
          return;
      }
      
      setErrMsg('');
      setCanResend(false);
      setTimeLeft(60); // Reset timer

      const { success, error } = await verifyEmail(email);

      if (!success) {
        setErrMsg(error);
        errRef?.current?.focus();
      }
    }
  }

  const handleEmailBack = () => {
    setErrMsg('');
    navigate(-1);
  }

  const handlePassBack = () => {
    setOtpCode('');
    setPassword('');
    setMatchPwd('');
    setErrMsg('');
    setOtpSent(false);
    setCanResend(false);
    setTimeLeft(60);
  }

  const handleSuccessClick = () => {
    // Emptying the fields here is redundant but just in case
    setOtpCode('');
    setPassword('');
    setMatchPwd('');
    setErrMsg('');
    setOtpSent(false);
    setTimeLeft(60);
    setChangeSuccess(false);

    navigate('/login');
  }

  return (
    <>
      <div className='moving-background'></div>
      <div className='flex-center full-view'>
        {!otpSent ? (
          <form
            onSubmit={handleEmail}
            className='white-container size-big responsive-form'
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className='back-arrow'
                onClick={handleEmailBack}
              />
              {errMsg && (
                <span
                  className='error-message'
                  style={{ flexGrow: 1, marginInline: 20 }}
                >
                  {errMsg}
                </span>
              )}
              <Loader active={isLoading} style={{position: 'absolute', right: 0}}/>
            </div>
            <h2 className='text-center'>
              <img src={logo} alt='Logo' className='responsive-logo' />
            </h2>
            <input
              type='text'
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='form-input'
              required
            />
            <button type='submit' className='submit-button' disabled={isLoading}>
              Verificar
            </button>
          </form>
        ) : !changeSuccess ? (
          <form
            onSubmit={handlePassChange}
            className='white-container size-big responsive-form'
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className='back-arrow'
                onClick={handlePassBack}
              />
              {errMsg && (
                <span
                  className='error-message'
                  style={{ flexGrow: 1, marginInline: 20 }}
                >
                  {errMsg}
                </span>
              )}
              <Loader active={isLoading} style={{position: 'absolute', right: 0}}/>
            </div>
            <h2 className='text-center'>
              <img src={logo} alt='Logo' className='responsive-logo' />
            </h2>
            <p style={{textAlign: 'center'}}>
              Hemos enviado un código de confirmación a{" "}
              {email ? (
                <b style={{ wordWrap: "break-word" }}>{email}</b>
              ) : (
                "su dirección de correo electrónico"
              )}
              . Por favor revise su bandeja de entrada (y la carpeta de spam, por si acaso)
              e ingrese el código.
            </p>
            <input
              type='text'
              placeholder='Codigo'
              value={optCode}
              onChange={(e) => setOtpCode(e.target.value)}
              className='form-input'
              autoComplete="off"
              required
            />
            <input
              type='password'
              placeholder='Nueva Contraseña'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='form-input'
              autoComplete="off"
              required
            />
            <input
              type='password'
              placeholder='Confirmar Contraseña'
              value={matchPwd}
              onChange={(e) => setMatchPwd(e.target.value)}
              className='form-input'
              autoComplete="off"
              required
            />
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault();
                handleOtpResend();
              }}
              className={canResend ? 'link-active' : 'link-disabled'}
              style={{ alignSelf: 'center' }}
            >
              Reenviar código ({timeLeft})
            </a>
            <button type='submit' className='submit-button'>
              Cambiar Contraseña
            </button>
          </form>
        ) : (
          <div className='white-container size-big responsive-form'>
            <h2 className='text-center'>
              <img src={logo} alt='Logo' className='responsive-logo' />
            </h2>
            <p
              style={{ alignSelf: 'center', fontWeight: 'bold', fontSize: 20, textAlign: 'center' }}
            >
              Cambio de contraseña exitoso
            </p>
            <button className='submit-button' onClick={handleSuccessClick}>
              Volver a inicio de sesión
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ForgotPasswordForm;
