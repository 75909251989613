import React from 'react';

const Journal = () => {
  return (
    <div>
      <div className="center-page">
        <h2>Reportes</h2>
      </div>
    </div>
  );
};

export default Journal;
