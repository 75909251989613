import React, { useState } from 'react';
import registerUserService from '../../../../services/registerServices/registerUserService';

const NewUser = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    fullname: '',
    email: '',
    details: '',
    roleid: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await registerUserService.registerUser({
      username: formData.username,
      fullname: formData.fullname,
      password: formData.password,
      email: formData.email,
      details: formData.details,
      roleid: formData.roleid,
    });

    if (result.success) {
      console.log('Usuario registrado:', result.message);
    } else {
      console.error('Error al registrar el usuario:', result.message, result.errorData);
    }
  };

  return (
    <div className='form-container'>
      <div className="white-container size-stretch text-center">
        <h1>Registrar Nuevo Usuario</h1>
      </div>
      <br/>
      <form onSubmit={handleSubmit} className='white-container size-big'>
        <label>
          Nombre de Usuario:
          <input className='form-input' type='text' name='username' value={formData.username} onChange={handleChange} required />
        </label>
        <label>
          Contraseña:
          <input className='form-input' type='password' name='password' value={formData.password} onChange={handleChange} required />
        </label>
        <label>
          Nombre Completo:
          <input className='form-input' type='text' name='fullname' value={formData.fullname} onChange={handleChange} required />
        </label>
        <label>
          Correo Electrónico:
          <input className='form-input' type='email' name='email' value={formData.email} onChange={handleChange} required />
        </label>
        <label>
          Detalles:
          <input className='form-input' type='text' name='details' value={formData.details} onChange={handleChange} />
        </label>
        <label>
          ID del Rol:
          <input className='form-input' type='text' name='roleid' value={formData.roleid} onChange={handleChange} required />
        </label>
        <button className='submit-button blue' type='submit'>
          Registrar Usuario
        </button>
      </form>
    </div>
  );
};

export default NewUser;
