import { BASE_URL } from '../apiConfig';

const registerMachineService = {
  registerMachine: async ({
    name,
    idLocation,
    boardKey,
    moneyIn,
    moneyOut,
    period,
    expirationDate,
    softwareVersion
  }) => {
    try {
      const url = `${BASE_URL}/register_machine.php?name=${encodeURIComponent(name)}&idLocation=${encodeURIComponent(idLocation)}&boardKey=${encodeURIComponent(boardKey)}&moneyIn=${encodeURIComponent(moneyIn)}&moneyOut=${encodeURIComponent(moneyOut)}&period=${encodeURIComponent(period)}&expirationDate=${encodeURIComponent(expirationDate)}&softwareVersion=${encodeURIComponent(softwareVersion)}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // Cambiado a application/x-www-form-urlencoded
        },
      });

      if (response.ok) {
        const data = await response.json();

        if ('Error' in data) {
          console.error('Error during machine registration:', data.Error);
          return { success: false, message: data.Error };
        } else {
          console.log('Machine registered successfully:', data);
          return { success: true, message: 'Máquina creada exitosamente' };
        }
      } else {
        console.error('Error during machine registration:', response.statusText);
        return { success: false, message: 'Error al crear la máquina', errorData: { statusText: response.statusText } };
      }
    } catch (error) {
      console.error('Network error during machine registration:', error);
      return { success: false, message: 'Error de red al crear la máquina', errorData: { networkError: error } };
    }
  },
};

export default registerMachineService;
