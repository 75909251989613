import React, { useState, useEffect, useRef } from 'react';
import getHashTournamentService from '../../../../services/hashServices/getHashTournamentService';
import jsQR from 'jsqr';

const Tournament = () => {

  const qrImageRef = useRef(null);

  const [showIframe, setShowIframe] = useState(false);
  const toggleIframeVisibility = () => {
    setShowIframe(!showIframe);
  };

  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [input4, setInput4] = useState('');
  const [input5, setInput5] = useState('');
  const [input6, setInput6] = useState('');
  const [input7, setInput7] = useState('');
  const [input8, setInput8] = useState('');
  const [outputText5, setOutputText5] = useState('');

  useEffect(() => {
    const handleMessage = (event) => {
      const data = event.data;
      setShowIframe(false);
      fillData(data.toString());
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  function fillData(dataString) {
    console.log("Detected!");

    const rawInput1 = /Input:\s*(\d+)/;
    const rawInput2 = /Output:\s*(\d+)/;
    const rawInput4 = /Machine\s*ID:\s*([^,\n]+)/;
    const rawInput3 = /Valid\s*ID:\s*(\d+)/;
    const rawInput5 = /V:\s*([^,\n]+)/;
    const rawInput8 = /SH:\s*([^,\n]+)/;

    const matchInput1 = typeof dataString === 'string' ? dataString.match(rawInput1) : null;
    const matchInput2 = typeof dataString === 'string' ? dataString.match(rawInput2) : null;
    const matchInput4 = typeof dataString === 'string' ? dataString.match(rawInput4) : null;
    const matchInput3 = typeof dataString === 'string' ? dataString.match(rawInput3) : null;
    const matchInput5 = typeof dataString === 'string' ? dataString.match(rawInput5) : null;
    const matchInput8 = typeof dataString === 'string' ? dataString.match(rawInput8) : null;

    if (matchInput1) { setInput1(matchInput1[1]); }
    if (matchInput2) { setInput2(matchInput2[1]); }
    if (matchInput4) { setInput4(matchInput4[1]); }
    if (matchInput3) { setInput3(matchInput3[1]); }
    if (matchInput5) { setInput5(matchInput5[1]); }
    if (matchInput8) { setInput8(matchInput8[1]); }
  }

  const getHashTournament = async () => {
    try {
      const result = await getHashTournamentService.getHashTournament({
        input1,
        input2,
        input3,
        input4,
        input5,
        input6,
        input7,
        input8
      });

      if (result.success) {

        console.log(result);
        setOutputText5(result.hash);
      } else {
        console.error(result.message, result.errorData);
      }

    } catch (error) { 
      console.error('Error durante el registro:', error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            fillData(code.data);
          } else {
            console.error('No QR code found.');
          }
        };
        image.src = e.target.result;
      };
      reader.readAsDataURL(file);
      toggleIframeVisibility();
    }
  };

  return (
    <>
      {showIframe ? (
      <>
        <div className='iframe-container'>
          <iframe
            title='QRReader'
            src='/QRReader/index.html'
            width='100%'
            height='100%'
            id='postiFrame'
          />
          <div className='iframe-buttons'>
            <button
              className='button-vertical submit-button blue'
              onClick={toggleIframeVisibility}
              style={{ minWidth: 150, maxWidth: 400 }}
            >
              Volver
            </button>
            <div
              style={{ minWidth: 150, maxWidth: 400, width: '100%'}}>
              <input
                type='file'
                ref={qrImageRef}
                accept='image/*'
                hidden
                onChange={handleFileChange}
              />
              <button
                className='button-vertical submit-button blue'
                onClick={() => qrImageRef.current?.click()}
                style={{ textWrap: 'nowrap' }}
              >
                Cargar Imagen
              </button>
            </div>
          </div>
        </div>
      </>
    ) : (
      <div className='form-container'>
        <div className="white-container size-stretch text-center">
          <h1>Generar Código para Modo Torneo</h1>
        </div>
        <br/>
        <div className="white-container size-big">
          <div>
            <input className='form-input' type="text" id="input1" placeholder="Ingreso" value={input1} onChange={(e) => setInput1(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input2" placeholder="Egreso" value={input2} onChange={(e) => setInput2(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input4" placeholder="MaquinaID" value={input4} onChange={(e) => setInput4(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input3" placeholder="Validador" value={input3} onChange={(e) => setInput3(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input5" placeholder="Version de Software" value={input5} onChange={(e) => setInput5(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input6" placeholder="Duración Modo Torneo" value={input6} onChange={(e) => setInput6(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input7" placeholder="Premio Modo Torneo" value={input7} onChange={(e) => setInput7(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input8" placeholder="ID Sistema" value={input8} onChange={(e) => setInput8(e.target.value)} /><br/>
          </div>
        </div>
        <br/>
        <div className="white-container size-stretch">
          <div>
            <button className="submit-button light-green" onClick={getHashTournament}> 
              Generar Código de Validación
            </button>
            <button className="button-vertical submit-button blue" onClick={toggleIframeVisibility}>
              Cargar Desde QR
            </button>
            <p id="outputText5" style={{ textAlign: 'center', fontWeight: 'bold' }}><h3>{"Código: " + outputText5}</h3></p>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

export default Tournament;
