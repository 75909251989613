import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { updateUserDataService, getUserDataService } from '../../../../services/CRUD/UserDataService';

const EditUser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: '',
    username: '',
    password: '',
    fullname: '',
    email: '',
    details: '',
    roleid: '',
  });
  const [passwordValue, setPasswordValue] = useState('');

  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserDataService(userId);
        if (response.Success && response.Success.length > 0) {
          const userData = response.Success[0];
          setFormData({
            id: userId,
            username: userData.USERNAME,
            password: '', 
            fullname: userData.FULLNAME,
            email: userData.EMAIL,
            details: userData.DETAILS,
            roleid: userData.ROLE_ID.toString(),
          });
        } else {
          console.error('Error al obtener los datos del usuario:', response.message);
        }
      } catch (error) {
        console.error('Error durante la solicitud:', error);
      }
    };

    fetchData();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === 'password') {
      setPasswordValue(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await updateUserDataService(formData);

    if (result.success) {
      console.log('Usuario actualizado:', result.message);
      handleNavigation(`/views/users-review/`);
    } else {
      console.error('Error al actualizar el usuario:', result.message, result.errorData);
    }
  };

  return (
    <div className='form-container'>
      <div className="white-container size-stretch text-center">
        <h1>Editar Usuario</h1>
      </div>
      <br/>
      <form onSubmit={handleSubmit} className='white-container size-big' autoComplete="off">
        <label>
          Nombre de Usuario:
          <input className='form-input' type='text' name='username' value={formData.username} onChange={handleChange} required autoComplete="off" />
        </label>
        <label>
          Contraseña:
          <input className='form-input' type='password' name='password' value={passwordValue} onChange={handleChange} placeholder='********' autoComplete="new-password" />
        </label>
        <label>
          Nombre Completo:
          <input className='form-input' type='text' name='fullname' value={formData.fullname} onChange={handleChange} required autoComplete="off" />
        </label>
        <label>
          Correo Electrónico:
          <input className='form-input' type='email' name='email' value={formData.email} onChange={handleChange} required autoComplete="off" />
        </label>
        <label>
          Detalles:
          <input className='form-input' type='text' name='details' value={formData.details} onChange={handleChange} autoComplete="off" />
        </label>
        <label>
          ID del Rol:
          <input className='form-input' type='text' name='roleid' value={formData.roleid} onChange={handleChange} required autoComplete="off" />
        </label>
        <button className='submit-button blue' type='submit'>
          Actualizar Usuario
        </button>
      </form>
    </div>
  );
};

export default EditUser;
