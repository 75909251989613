import React, { useEffect, useState } from 'react';
import { getLocals } from '../../../../services/viewsServices/userLocationsServices'; 
import { useAuth } from '../../../../contexts/AuthContext';
import { useData } from '../../../../contexts/DataContext';
import { deleteLocalDataService } from '../../../../services/CRUD/LocalDataService';
import { Link, useNavigate } from 'react-router-dom';
import { useHasPrivileges  } from '../../../../contexts/DataContext';

const LocalReview = () => {
  const { user } = useAuth();
  const { state } = useData();
  const { userData } = state;
  const [machines, setMachines] = useState([]);
  const navigate = useNavigate();
  const hasPrivileges = useHasPrivileges();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const fetchData = async () => {
    try {
      const response = await getLocals(userData.username);
      if (response.Success) {
        const data = response.Success;
        setMachines(data);
        console.log("Fetched data: ", data);
      } else {
        console.error('Respuesta de la API no tiene la propiedad "Success":', response);
      }
    } catch (error) {
      console.error('Error durante la solicitud:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const result = await deleteLocalDataService(id);

      if (result.success) {
        console.log('Usuario eliminado:', result.message);
        fetchData();
      } else {
        console.error('Error al eliminar el usuario:', result.message);
      }
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
    }
  };

  return (
    <div className='white-container size-big' style={{ width: '90%', overflow: 'auto'}}>
      <h2>Vista de Locales</h2>
      <table style={{overflow: 'auto'}}>
        <thead>
          <tr>
            <th style={{ width: '1%' }}>Nº</th>
            <th style={{ width: '75%' }}>Nombre Local</th>
            {hasPrivileges(['UPDATE LOCAL']) && (  <th style={{ width: '15%' }}>Acciones</th> )}
          </tr>
        </thead>
        <tbody >
          {Array.isArray(machines) && hasPrivileges(['READ LOCAL']) && machines.length > 0 && machines.map((machine, index) => (
            <tr key={machine.ID} style={{ height: '50px'}} className={`text-center form-margin ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
              <td>{machine.ID}</td>
              <td className='text-left'>{machine.NAME}</td>
              {hasPrivileges(['UPDATE LOCAL']) && (
              <td>
                {hasPrivileges(['UPDATE LOCAL']) && ( <button className='stylish-button blue' onClick={() => handleNavigation(`/views/local-review/edit-local/${machine.ID}`)}>Editar</button> )}
                {hasPrivileges(['DELETE LOCAL']) && ( <button className='stylish-button red' onClick={() => handleDelete(machine.ID)}>Eliminar</button> )}
              </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );  
};

export default LocalReview;
