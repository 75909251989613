import React, { useState } from 'react';
import logo from '../../../assets/logo/tatan-logo-white.png';
import './Header.css';
import { useAuth } from '../../../contexts/AuthContext';
import { useData } from '../../../contexts/DataContext';
import { useNavigate } from 'react-router-dom';

const Header = ({ menuActive, setMenuActive }) => {
  const { user } = useAuth();
  const { state } = useData();
  const { userData } = state;
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setMenuActive(false);
  };  

  return (
    <header className="header flex-space" style={{ zIndex: 1 }}>
      <div className="burger-menu" onClick={toggleMenu}>☰</div>
      <div className="user-info"> <p>Bienvenido, {userData.fullname}</p> </div>
    </header>

  );
};

export default Header;