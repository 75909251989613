import React from 'react';

const Forbidden = () => {
  return (
    <div className='forbidden-container'>
      <h1>403 Forbidden</h1>
      <p>Oops! Parece que no tienes permisos para acceder a esta página.</p>
    </div>
  );
};

export default Forbidden;