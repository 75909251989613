import React, { useState } from "react";
import Header from "./HUD/Header/Header";
import Sidebar from "./HUD/Sidebar/Sidebar";
import './Layout.css'

const Layout = ({ children }) => {
  const [menuActive, setMenuActive] = useState(false);
  
  return (
    <div>
      <Sidebar menuActive={menuActive} setMenuActive={setMenuActive} />
      <div className="main-content">
        <Header menuActive={menuActive} setMenuActive={setMenuActive} />
        <div className="main-background"></div> 
        <div className={`${menuActive ? 'black-collider' : ''}`} onClick={() => setMenuActive(false)} />

        <div className="head-container">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;