import { BASE_URL } from '../apiConfig';

const registerUserService = {
    registerUser: async ({
        username,
        fullname,
        password,
        email,
        details,
        roleid
      }) => {
        try {
          const url = `${BASE_URL}/register_user.php?username=${encodeURIComponent(username)}&fullname=${encodeURIComponent(fullname)}&password=${encodeURIComponent(password)}&email=${encodeURIComponent(email)}&details=${encodeURIComponent(details)}&roleid=${encodeURIComponent(roleid)}`
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
    
          if (response.ok) {
            const data = await response.json();
    
            if ('Error' in data) {
              console.error('Error during Local registration:', data.Error);
              return { success: false, message: data.Error };
            } else {
              console.log('Local registered successfully:', data);
              return { success: true, message: 'Local creada exitosamente' };
            } 
          } else {
            console.error('Error during local registration:', response.statusText);
            return { success: false, message: 'Error al crear local', errorData: { statusText: response.statusText } };
          }
        } catch (error) {
          console.error('Network error during local registration:', error);
          return { success: false, message: 'Error de red al crear local', errorData: { networkError: error } };
        }
      },
    };
  
  export default registerUserService;