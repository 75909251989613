import { BASE_URL } from './apiConfig';

const getMinBoardKey = async () => {
    try {
      const url = `${BASE_URL}/get_min_boardkey.php`;
      const response = await fetch(url);
  
      if (response.ok) {
        const data = await response.json();
  
        if ('Error' in data) {
          console.error('Error fetching MinBoardKey:', data.Error);
          return { error: data.Error };
        } else {
          console.log('MinBoardKey fetched successfully:', data);
          return { success: data };
        }
      } else {
        console.error('Failed to fetch MinBoardKey');
        return { error: 'Failed to fetch MinBoardKey' };
      }
    } catch (error) {
      console.error('Network error:', error);
      return { error: 'Network error' };
    }
  };
  
  export { getMinBoardKey };
  