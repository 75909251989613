import { BASE_URL } from '../apiConfig';

const getHashTournamentService = {
    getHashTournament: async ({
      input1,
      input2,
      input3,
      input4,
      input5,
      input6,
      input7,
      input8
    }) => {
      try {
        const url = `${BASE_URL}/get_hash_tournament.php?input1=${encodeURIComponent(input1)}&input2=${encodeURIComponent(input2)}&input3=${encodeURIComponent(input3)}&input4=${encodeURIComponent(input4)}&input5=${encodeURIComponent(input5)}&input6=${encodeURIComponent(input6)}&input7=${encodeURIComponent(input7)}&input8=${encodeURIComponent(input8)}`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // Cambiado a application/x-www-form-urlencoded
          },
        });
  
        if (response.ok) {
          const data = await response.json();
  
          if ('Error' in data) {
            console.error('Error during machine update:', data.Error);
            return { success: false, message: data.Error, hash: data.hash };
          } else {
            console.log('Machine registered successfully:', data);
            return { success: true, message: 'Máquina actualizada exitosamente', hash: data.Hash };
          }
        } else {
          console.error('Error during machine update:', response.statusText);
          return { success: false, message: 'Error al actualizar la máquina', errorData: { statusText: response.statusText } };
        }
      } catch (error) {
        console.error('Network error during machine update:', error);
        return { success: false, message: 'Error de red al actualizar la máquina', errorData: { networkError: error } };
      }
    },
  };
  
  export default getHashTournamentService;