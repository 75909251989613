import { useState, useEffect, useRef } from 'react';
import { useData } from '../../../contexts/DataContext';
import { BASE_URL } from '../../../services/apiConfig';
import jsQR from 'jsqr';
import Loader from '../../Loader/Loader';

import '../../../styles/MovingBackground.css';
import '../../../styles/misc/Forms.css';
import '../../../styles/misc/Data.css';

const CashierPanel = () => {
  const { state } = useData();
  const { userData } = state;
  const qrImageRef = useRef(null);
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const [showIframe, setShowIframe] = useState(false);
  const toggleIframeVisibility = () => {
    setShowIframe(!showIframe);
  };

  const [amount, setAmount] = useState('');
  const [moneyIn, setMoneyIn] = useState('');
  const [moneyOut, setMoneyOut] = useState('');
  const [machineID, setMachineID] = useState('');
  const [validID, setValidID] = useState('');
  const [period, setPeriod] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [softwareVersion, setSoftwareVersion] = useState('');
  const [systemHash, setSystemHash] = useState('');
  const [curGame, setCurGame] = useState('');

  useEffect(() => {
    const handleMessage = (event) => {
      console.log('Message received from iframe:', event.data);
      const data = event.data;
      setShowIframe(false);
      fillData(data.toString());
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  function fillData(dataString) {
    console.log("Detected!");

    const amountPattern = /Amount:\s*(\d+),/;
    const moneyInPattern = /In:\s*(\d+),/;
    const moneyOutPattern = /Out:\s*(\d+),/;
    const machineIDPattern = /ID:\s*([^,]+),/;
    const expireDatePattern = /Expire\s*Date:\s*([^,]+),/;
    const softwareVersionPattern = /V:\s*([\d.]+),/;
    const systemHashPattern = /SH:\s*([^,]+),/;
    const curGamePattern = /CurGame:\s*([^,]+),/;
    const validIDPattern = /Valid\s*ID:\s*([^,]+),/;

    const matchAmount = typeof dataString === 'string' ? dataString.match(amountPattern) : null;
    const matchMoneyIn = typeof dataString === 'string' ? dataString.match(moneyInPattern) : null;
    const matchMoneyOut = typeof dataString === 'string' ? dataString.match(moneyOutPattern) : null;
    const matchMachineID = typeof dataString === 'string' ? dataString.match(machineIDPattern) : null;
    const matchExpireDate = typeof dataString === 'string' ? dataString.match(expireDatePattern) : null;
    const matchSoftwareVersion = typeof dataString === 'string' ? dataString.match(softwareVersionPattern) : null;
    const matchSystemHash = typeof dataString === 'string' ? dataString.match(systemHashPattern) : null;
    const matchCurGame = typeof dataString === 'string' ? dataString.match(curGamePattern) : null;
    const matchValidID = typeof dataString === 'string' ? dataString.match(validIDPattern) : null;

    if (matchAmount) {
      setAmount(matchAmount[1]);
    }

    if (matchMoneyIn) {
        setMoneyIn(matchMoneyIn[1]);
    }

    if (matchMoneyOut) {
        setMoneyOut(matchMoneyOut[1]);
    }

    if (matchMachineID) {
        setMachineID(matchMachineID[1]);
    }

    if (matchExpireDate) {
      const expireDate = new Date(matchExpireDate[1]);
      const formattedDate = expireDate.toISOString().split('T')[0];
      setExpirationDate(formattedDate);

      // Calculate period
      const today = new Date();
      const diffTime = Math.abs(expireDate - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setPeriod(diffDays);
    }

    if (matchSoftwareVersion) {
        setSoftwareVersion(matchSoftwareVersion[1]);
    }

    if (matchSystemHash) {
        setSystemHash(matchSystemHash[1]);
    }

    if (matchCurGame) {
        setCurGame(matchCurGame[1]);
    }

    if (matchValidID) {
        setValidID(matchValidID[1]);
    }
  }

  const clearSuccessSetError = (message) => {
    setSuccessMsg('');
    setErrMsg(message);
  }

  const handleOpenQR = () => {
    clearSuccessSetError('');
    toggleIframeVisibility();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            console.log('DATA: ', code.data);
            fillData(code.data);
          } else {
            console.error('No se detectó un QR en la imagen.');
            clearSuccessSetError('No se detectó un QR en la imagen.')
          }
        };
        image.src = e.target.result;
      };
      reader.readAsDataURL(file);
      toggleIframeVisibility();
    }
  };

  const submitData = async () => {
    if (!isLoading) {
      if (
        !amount ||
        !moneyIn ||
        !moneyOut ||
        !machineID ||
        !validID ||
        !period ||
        !expirationDate ||
        !softwareVersion ||
        !systemHash ||
        !curGame
      ) {
        clearSuccessSetError("QR inválido, faltan datos.");
        errRef?.current?.focus();
        return;
      }
      clearSuccessSetError('');
      setIsLoading(true);
      const queryParams = new URLSearchParams({
        amount,
        moneyIn,
        moneyOut,
        machineID,
        validID,
        period,
        expirationDate,
        softwareVersion,
        systemHash,
        curGame,
      });
      try {
        const response = await fetch(
          `${BASE_URL}/cashier_machine_update.php?${queryParams.toString()}`
        );

        if (response.ok) {
          console.log('response.ok', response, response.ok);
          setAmount('');
          setMoneyIn('');
          setMoneyOut('');
          setMachineID('');
          setValidID('');
          setPeriod('');
          setExpirationDate('');
          setSoftwareVersion('');
          setSystemHash('');
          setCurGame('');
          setSuccessMsg('Máquina actualizada exitosamente.')
          
        } else { // Handle specific HTTP error codes
          switch (response.status) {
            case 400:
              console.error('Formato de solicitud incorrecto.');
              clearSuccessSetError('Formato de solicitud incorrecto.');
              break;
            case 409:
              console.error('QR inválido. El QR ya ha sido utilizado.');
              clearSuccessSetError('QR inválido. El QR ya ha sido utilizado.');
              break;
            case 404:
              console.log('called1', response.status);
              console.error('No se encontraron resultados para esta máquina.');
              clearSuccessSetError('No se encontraron resultados para esta máquina.');
              break;
            default: // Most likely just a 500 response
              console.error('Error del servidor, por favor intente más tarde.');
              clearSuccessSetError('Error del servidor, por favor intente más tarde.');
              break;
          }
        }
      } catch (error) {
        console.error('Error:', error);
        clearSuccessSetError('Error, por favor intente más tarde.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className='moving-background'></div>
      {showIframe ? (
        <div className='flex-center full-view-flexible'>
          <div className='iframe-container'>
            <iframe
              title='QRReader'
              src='/QRReader/index.html'
              width='100%'
              height='100%'
              id='postiFrame'
            />
            <div className='iframe-buttons'>
              <button
                className='button-vertical submit-button blue'
                onClick={toggleIframeVisibility}
                style={{ minWidth: 150, maxWidth: 400 }}
              >
                Volver
              </button>
              <div style={{ minWidth: 150, maxWidth: 400, width: '100%' }}>
                <input
                  type='file'
                  ref={qrImageRef}
                  accept='image/*'
                  hidden
                  onChange={handleFileChange}
                />
                <button
                  className='button-vertical submit-button blue'
                  onClick={() => qrImageRef.current?.click()}
                  style={{ textWrap: 'nowrap' }}
                >
                  Cargar Imagen
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex-center full-view-flexible'>
          <div className='data-form pad-vertical-no-layout'>
            <div className='white-container size-stretch text-center'>
              <h1>Escanear código QR</h1>
              {errMsg && (
                <span className='error-message'>
                  {errMsg}
                </span>
              )}
              {successMsg && (
                <span className='success-message'>
                  {successMsg}
                </span>
              )}
            </div>
            <br />
            <div className='data-container'>
              <table className='data-table'>
                <tbody>
                  <tr>
                    <th className='label'>Monto</th>
                    <td>{amount}</td>
                  </tr>
                  <tr>
                    <th className='label'>Ingreso</th>
                    <td>{moneyIn}</td>
                  </tr>
                  <tr>
                    <th className='label'>Egreso</th>
                    <td>{moneyOut}</td>
                  </tr>
                  <tr>
                    <th className='label'>Tiempo</th>
                    <td>{period}</td>
                  </tr>
                  <tr>
                    <th className='label'>Fecha de expiración</th>
                    <td>{expirationDate}</td>
                  </tr>
                  <tr>
                    <th className='label'>Juego</th>
                    <td>{curGame}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <div className='white-container size-stretch'>
              <div>
                <button
                  className={`button-vertical submit-button ${
                    isLoading ? 'button-disabled' : ''
                  }`}
                  onClick={handleOpenQR}
                  disabled={isLoading}
                >
                  Abrir escáner de QR
                </button>
                <button
                  className={`submit-button light-green ${
                    isLoading ? 'button-disabled' : ''
                  }`}
                  disabled={isLoading}
                  onClick={submitData}
                >
                  Enviar datos
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

};

export default CashierPanel;