import JSZip from 'jszip';

export const createZipFile = (files) => {
  const zip = new JSZip();
  const folderName = '3f183f35c65d420e302f646fce08361a'; // Nombre de la carpeta

  // Crea una carpeta en el zip
  const folder = zip.folder(folderName);

  // Añade cada archivo a la carpeta
  files.forEach(({ name, content }) => {
    folder.file(name, content);
  });

  // Genera el archivo zip como un blob
  return zip.generateAsync({ type: 'blob' });
};