import { BASE_URL } from '../apiConfig';

const getUserOnMyLocations = async (name) => {
    try {
        const url = `${BASE_URL}/get_user_on_my_locations.php?username=${encodeURIComponent(name)}`;
        const response = await fetch(url);

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            return data;
        } else {
            console.error('Error en la solicitud HTTP:', response.statusText);
            return { error: `Error en la solicitud HTTP: ${response.statusText}` };
        }
    } catch (error) {
        console.error('Error durante la solicitud:', error);
        return { error: 'Error durante la solicitud' };
    }
};

export { getUserOnMyLocations };
