import { BASE_URL } from './apiConfig';

const authenticateUser = async (username, password) => {
    try {
      const url = `${BASE_URL}/login_user.php?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });
  
      if (response.ok) {
        
        const data = await response.json();
  
        if ('Error' in data) {
          console.error('Error during login:', data.Error);
          return { error: data.Error };
        } else {
          const permissions = data.Permissions;
          const userData = data.Data;

          return { success: { permissions, userData } };
        }
      } else {
        console.error('Invalid credentials');
        return { error: 'Invalid credentials' };
      }
    } catch (error) {
      console.error('Network error:', error);
      return { error: 'Network error' };
    }
  };
  
export { authenticateUser };