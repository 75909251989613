import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './styles/Global.css';
import './styles/Color.css';
import './styles/Fonts.css';
/* import './styles/Background.css'; */
/* import './styles/MovingBackground.css'; */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
