import React, { useState, useEffect } from 'react';
import registerMachineService from '../../../../services/registerServices/registerMachineService';
import { getAllLocations } from '../../../../services/locationsService';
import { getAllCountries } from '../../../../services/countriesService';
import { getAllGames } from '../../../../services/gamesService';
import { getMinBoardKey } from '../../../../services/minBoardKey';

import { downloadFiles } from './ZipDownloadUtils';
import { EncryptData } from './CryptoUtils';

const NewBundles = () => {
  const [formData, setFormData] = useState({
    name: '', 
    idLocation: '',
    boardKey: '',
    moneyIn: '',
    moneyOut: '',
    period: '',
    expirationDate: '',
    softwareVersion: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'country') {
      const selectedCountry = countries.find(country => country.ID === value);
      setSelectedCountryName(selectedCountry ? selectedCountry.NAME : '');
    }
    if (name === 'name') {
      const selectedGameSH = games.find(game => game.NAME === value);
      formData.idGame = selectedGameSH.ID;
      setSelectedGameSH(selectedGameSH ? selectedGameSH.GAME_SH : '');
    }

    if (name === 'orientation') {
      setSelectedOrientation(value);
    }

    if (name === 'idLocation') {
      const selectedLocation = locations.find(location => location.ID === value);
      setSelectedLocationName(selectedLocation ? selectedLocation.NAME : '');
    }
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.idLocation || !formData.orientation || !formData.machineCount || !formData.softwareVersion) {
      console.error('Por favor, complete todos los campos obligatorios.');
      return;
    }
    fetchMinBoardKey();

    try {
      const minValue = parseInt(minBoardKey[0].MinBoardKey, 10);
      const maxValue = minValue+parseInt(formData.machineCount,10);
      for (let i = minValue; i < maxValue; i++) {
        const machineMID = `${selectedGameSH}-${selectedLocation}-${selectedOrientation}-${selectedCountryName}-${i}`;
        console.log("Building => " + machineMID);
        const result = await registerMachineService.registerMachine({
          name: formData.idGame,
          idLocation: formData.idLocation,
          boardKey: machineMID,
          moneyIn: 0,
          moneyOut: 0,
          period: 0,
          expirationDate: 0,
          softwareVersion: formData.softwareVersion,
        });
  
        if (result.success) {
          console.log(result.message);
          handleDownload(machineMID);
        } else {
          console.error(result.message, result.errorData);
        }
      }
    } catch (error) {
      console.error('Error durante el registro:', error);
    }
  };

  
  const [locations, setLocations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedCountryName, setSelectedCountryName] = useState('');
  const [selectedGameSH, setSelectedGameSH] = useState('');
  const [selectedLocation, setSelectedLocationName] = useState('');
  const [selectedOrientation, setSelectedOrientation] = useState('');
  const [minBoardKey, setMinBoardKey] = useState('');

  //codeData => MGS-V-CL-TATAN-10-1012
  const handleDownload = async (codeData) => {
    const unusedJpgResponse = await fetch('../../../../assets/bundle_files/unused.jpg');
    const unusedJpgContent = await unusedJpgResponse.arrayBuffer();
    const unusedJpgBlob = new Blob([unusedJpgContent]);

    const orientation = 'V';
    const files = [
      { name: 'RS3a2d7564baee79182ebc7b65084aabd1', content: orientation === 'V' ? '3e1b74251c07310c5f1b968145bf00dc' : '86e5d0d8407ce71f7e2004ef3949894e' },
      { name: 'IS0bfc16cc12effc1bae4d3766c4f2257d', content: '7b804a28d6154ab8007287532037f1d0' },
      { name: 'eb5290e02200e034ea87b5aa59c1ca21', content: '' },
      { name: '2eb48c1fc4b0e8ec15e8eee169011279', content: EncryptData(codeData) },
      { name: 'unused.jpg', content: unusedJpgBlob  },
    ];

    downloadFiles(files, codeData+'.zip');
  };


  useEffect(() => {
    fetchGames();
    fetchCountries();
    fetchLocations();
    fetchMinBoardKey();
  }, []);


  const fetchLocations = async () => {
    const result = await getAllLocations();

    if ('success' in result) {
      setLocations(result.success);
    } else {
      console.error('Error:', result.error);
    }
  };

  const fetchCountries = async () => {
    const result = await getAllCountries();

    if ('success' in result) {
      setCountries(result.success);
    } else {
      console.error('Error:', result.error);
    }
  };

  const fetchGames = async () => {
    const result = await getAllGames();

    if ('success' in result) {
      setGames(result.success);
    } else {
      console.error('Error:', result.error);
    }
  };

  const fetchMinBoardKey = async () => {
    const result = await getMinBoardKey();

    if ('success' in result) {
      setMinBoardKey(result.success);
    } else {
      console.error('Error:', result.error);
    }
  };

  
  
  return (
    <div className='form-container'>
      <div className="white-container size-stretch text-center">
        <h1>Generar Bundles de Máquinas</h1>
      </div>
      <br/>
      <form onSubmit={handleSubmit} className='white-container size-big'>

        <label>
          Juego:
          <select className='form-input' name="name" value={formData.idGame} onChange={handleChange} >
            <option value="" disabled selected>Selecciona un Juego</option>
            {games.map(game => ( <option key={game.ID} value={game.GAME_ID}> {game.NAME} </option> ))}
          </select>
        </label>

        <label>
          Local:
          <select className='form-input' name="idLocation" value={formData.idLocation} onChange={handleChange} >
            <option value="" disabled selected>Selecciona una ubicación</option>
            {locations.map(location => ( <option key={location.ID} value={location.ID}> {location.NAME} </option> ))}
          </select>
        </label>

        <label>
          Pais:
          <select className='form-input' name="country" value={formData.idCountry} onChange={handleChange} >
            <option value="" disabled selected>Selecciona Pais</option>
            {countries.map(country => ( <option key={country.ID} value={country.ID}> {country.NAME} </option> ))}
          </select>
        </label>

        <label>
          Orientación:
          <select className='form-input' name="orientation" value={formData.orientation} onChange={handleChange} >
            <option value="" disabled selected>Selecciona orientación</option>
            <option value="V">Vertical</option>
            <option value="H">Horizontal</option>
          </select>
        </label>

        <label>
          Cantidad de Máquinas:
          <div className="slider-input-container">
            <input className="form-input" type="number" name="machineCount" value={formData.machineCount} onChange={handleChange} min="1" max="100" />
          </div>
        </label>

        <label>
          Versión del Software:
          <input className='form-input' type="text" name="softwareVersion" value={formData.softwareVersion} onChange={handleChange} />
        </label>

        <label>
          Vista Previa:
          <input className='form-input' type="text" name="boardKey" value={`${selectedGameSH}-${selectedLocation}-${selectedOrientation}-${selectedCountryName}-XXXX`} readOnly onChange={handleChange} />
        </label>

        <button className='submit-button blue' type="submit">Generar Bundles</button>
      </form>
    </div>
  );
};

export default NewBundles;
