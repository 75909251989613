import React, { useState, useEffect, useRef } from 'react';
import getHashValidService from '../../../../services/hashServices/getHashValidService.js';
import { useData } from '../../../../contexts/DataContext';
import jsQR from 'jsqr';

const Validation = () => {
  const { state } = useData();
  const { userData } = state;
  const qrImageRef = useRef(null);
  
  const [showIframe, setShowIframe] = useState(false);
  const toggleIframeVisibility = () => {
    setShowIframe(!showIframe);
  };

  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [input4, setInput4] = useState('');
  const [input5, setInput5] = useState('');
  const [input7, setInput7] = useState('');
  const [outputText5, setOutputText5] = useState('');
  const [fecha, setFecha] = useState('');

  useEffect(() => {
    const handleMessage = (event) => {
      const data = event.data;
      setShowIframe(false);
      fillData(data.toString());
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  function fillData(dataString) {
    console.log("Detected!");

    const inputPattern = /Input:\s*(\d+)/;
    const outputPattern = /Output:\s*(\d+)/;
    const machineIdPattern = /Machine\s*ID:\s*([^,\n]+)/;
    const validIdPattern = /Valid\s*ID:\s*(\d+)/;
    const expireDatePattern = /Expire\s*Date:\s*([^,\n]+)/;

    const matchInput = typeof dataString === 'string' ? dataString.match(inputPattern) : null;
    const matchOutput = typeof dataString === 'string' ? dataString.match(outputPattern) : null;
    const matchMachineId = typeof dataString === 'string' ? dataString.match(machineIdPattern) : null;
    const matchValidId = typeof dataString === 'string' ? dataString.match(validIdPattern) : null;
    const matchExpireDate = typeof dataString === 'string' ? dataString.match(expireDatePattern) : null;

    if (matchInput) {
      setInput1(matchInput[1]);
    }

    if (matchOutput) {
      setInput2(matchOutput[1]);
    }

    if (matchMachineId) {
      setInput5(matchMachineId[1]);
    }

    if (matchValidId) {
      setInput4(matchValidId[1]);
    }

    if (matchExpireDate) {
      const formattedDate = new Date(matchExpireDate[1]).toISOString().split('T')[0];
      setFecha(formattedDate);
    }
  }

  const getHash5 = async () => {
    try {
      const result = await getHashValidService.getHashValid({
        input1,
        input2,
        input3,
        input4,
        input5,
        input7
      });

      if (result.success) {

        console.log(result);
        setOutputText5(result.hash);
      } else {
        console.error(result.message, result.errorData);
      }

    } catch (error) { 
      console.error('Error durante el registro:', error);
    }
  };


  const updateDateFromInt = (e) => {
    var inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      inputValue = Math.max(inputValue, 0);
      inputValue = Math.min(inputValue, userData.max_valid_days);

      setInput3(inputValue);
      const today = new Date();
      today.setDate(today.getDate() + inputValue);
      const formattedDate = today.toISOString().split('T')[0];
      setFecha(formattedDate);
    } else {
      setInput3('');
      setFecha('');
    }
  };

  const updateNumberFromDate = (newDate) => {
    const today = new Date();
    const selectedDate = new Date(newDate);
    if (selectedDate >= today) {
      const diffInDays = Math.floor((selectedDate - today) / (24 * 60 * 60 * 1000));
      setInput3(diffInDays);
    } else {
      setInput3(0);
    }
  };

  const updateDate = (event) => {
    let newDate = new Date(event.target.value);
    newDate.setDate(newDate.getDate() + 1); // Agregar un día
  
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + userData.max_valid_days);
  
    if (newDate < today) {
      newDate = today;
    } else if (newDate >= maxDate) {
      newDate = maxDate;
    }
  
    const formattedDate = [newDate.getFullYear(), ('0' + (newDate.getMonth() + 1)).slice(-2), ('0' + newDate.getDate()).slice(-2)].join('-')
  
    setFecha(formattedDate);
    updateNumberFromDate(newDate);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            fillData(code.data);
          } else {
            console.error('No QR code found.');
          }
        };
        image.src = e.target.result;
      };
      reader.readAsDataURL(file);
      toggleIframeVisibility();
    }
  };

  return (
    <>
      {showIframe ? (
        <div className='iframe-container'>
          <iframe
            title='QRReader'
            src='/QRReader/index.html'
            width='100%'
            height='100%'
            id='postiFrame'
          />
          <div className='iframe-buttons'>
            <button
              className='button-vertical submit-button blue'
              onClick={toggleIframeVisibility}
              style={{ minWidth: 150, maxWidth: 400 }}
            >
              Volver
            </button>
            <div
              style={{ minWidth: 150, maxWidth: 400, width: '100%'}}>
              <input
                type='file'
                ref={qrImageRef}
                accept='image/*'
                hidden
                onChange={handleFileChange}
              />
              <button
                className='button-vertical submit-button blue'
                onClick={() => qrImageRef.current?.click()}
                style={{ textWrap: 'nowrap' }}
              >
                Cargar Imagen
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='form-container'>
          <div className='white-container size-stretch text-center'>
            <h1>Sistema de validación de Máquinas</h1>
          </div>
          <br />
          <div className='white-container size-big'>
            <div>
              <input
                className='form-input'
                type='text'
                id='input1'
                placeholder='Entrar Ingreso'
                value={input1}
                onChange={(e) => setInput1(e.target.value)}
              />
              <br />
              <input
                className='form-input'
                type='text'
                id='input2'
                placeholder='Entrar Egreso'
                value={input2}
                onChange={(e) => setInput2(e.target.value)}
              />
              <br />
              <input
                className='form-input'
                type='text'
                id='input5'
                placeholder='Entrar MaquinaID'
                value={input5}
                onChange={(e) => setInput5(e.target.value)}
              />
              <br />
              <input
                className='form-input'
                type='text'
                id='input4'
                placeholder='Entrar Validador'
                value={input4}
                onChange={(e) => setInput4(e.target.value)}
              />
              <br />
              <input
                className='form-input'
                type='number'
                id='input3'
                placeholder='Entrar Tiempo'
                value={input3}
                onInput={updateDateFromInt}
                min='0'
                max={userData.max_valid_days}
              />
              <br />
              <input
                className='form-input'
                type='date'
                id='fecha'
                name='fecha'
                onChange={updateDate}
                value={fecha}
              />
              <br />
              <input
                className='form-input'
                type='text'
                id='input7'
                placeholder='ID Sistema'
                value={input7}
                onChange={(e) => setInput7(e.target.value)}
              />
              <br />
            </div>
          </div>
          <br />
          <div className='white-container size-stretch'>
            <div>
              <button className='submit-button light-green' onClick={getHash5}>
                Generar Código de Validación
              </button>
              <button
                className='button-vertical submit-button blue'
                onClick={toggleIframeVisibility}
              >
                Cargar Desde QR
              </button>
              <p
                id='outputText5'
                style={{ textAlign: 'center', fontWeight: 'bold' }}
              >
                <>{'Código: ' + outputText5}</>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );

};

export default Validation;