import { BASE_URL } from './apiConfig';

const verifyEmail = async (email) => {
  try {
    const url = `${BASE_URL}/forgot_password.php?email=${encodeURIComponent(
      email
    )}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return { success: 200 };
    } else {
      // Handle specific HTTP error codes
      switch (response.status) {
        case 400:
          console.error('Formato de solicitud incorrecto.');
          return { error: 'Formato de solicitud incorrecto.' };
        case 429:
          console.error('Límite para envío de código excedido, por favor intente más tarde.');
          return { error: 'Límite para envío de código excedido, por favor intente más tarde.' };
        default: // Most likely just a 500 response
          console.error('Error del servidor, por favor intente más tarde.');
          return { error: 'Error del servidor, por favor intente más tarde.' };
      }
    }
  } catch (error) {
    console.error('Error, por favor intente más tarde.');
    return { error: 'Error, por favor intente más tarde.' };
  }
};
const attemptPassChange = async (email, otpCode, password) => {
    try {
      const url = `${BASE_URL}/change_password.php?email=${encodeURIComponent(email)}&otpCode=${encodeURIComponent(otpCode)}&password=${encodeURIComponent(password)}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          otpCode,
          password,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        return { success: 200 };
      } else {
        // Handle specific HTTP error codes
        switch (response.status) {
          case 400:
            console.error('Formato de solicitud incorrecto.');
            return { error: 'Formato de solicitud incorrecto.' };
          case 403:
            console.error('Código expirado. Por favor solicite un nuevo código o intente más tarde.');
            return { error: 'Código expirado. Por favor solicite un nuevo código o intente más tarde.' };
          case 404:
            console.error('Código incorrecto, por favor intente de nuevo.');
            return { error: 'Código incorrecto, por favor intente de nuevo.' };
          default: // Most likely just a 500 server error response
            console.error('Error del servidor, por favor intente más tarde.');
            return { error: 'Error del servidor, por favor intente más tarde.' };
        }
      }
    } catch (error) {
      console.error('Error, por favor intente más tarde.');
      return { error: 'Error, por favor intente más tarde.' };
    }
};

  
export { verifyEmail, attemptPassChange };