import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { useData } from '../../../contexts/DataContext';
import { Link } from 'react-router-dom';
import logo from '../../../assets/logo/tatan-logo.png'; 
import { useNavigate } from 'react-router-dom';
import { authenticateUser } from '../../../services/authService';
import Loader from '../../Loader/Loader';

import '../../../styles/misc/Forms.css';
import '../../../styles/MovingBackground.css';

const LoginForm = () => {
  const errRef = useRef();

  const { login, isAuthenticated } = useAuth();
  const { dispatch: dataDispatch } = useData();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrMsg('');
    setIsLoading(true);
    const { success, error } = await authenticateUser(username, password);

    if (success) {
      const { permissions, userData } = success;

      console.log('Permissions:', permissions);
      console.log('UserData:', userData);
      
      dataDispatch({ type: 'SET_USER_DATA', payload: userData });
      dataDispatch({ type: 'SET_PERMISSIONS', payload: permissions });
      console.log(Permissions);
      login({ username: username.toUpperCase() });
      if (userData?.role_id === 5) 
        navigate('/cashier-panel')
      else
        navigate('/home');
    } else {
      setErrMsg('Inicio de sesión fallo');
      errRef?.current?.focus();
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="moving-background"></div>
      <div className='flex-center full-view'>
        <form onSubmit={handleLogin} className="white-container size-big responsive-form">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
            {errMsg && (
              <span
                className='error-message'
                style={{ flexGrow: 1, marginInline: 20 }}
              >
                {errMsg}
              </span>
            )}
            <Loader active={isLoading} style={{position: 'absolute', right: 0}}/>
          </div>
          <h2 className='text-center'><img src={logo} className='responsive-logo' alt="Logo"/></h2>
          <input
            type="text"
            placeholder="Usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="form-input"
          />
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-input"
          />
          <Link className='right-responsive-link' to="/forgot-password">Olvidó su contraseña?</Link>
          <button type="submit" className="submit-button">
            Iniciar Sesión
          </button>
        </form>
      </div>
    </>
  );
};

export default LoginForm;
