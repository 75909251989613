import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLocalDataService, updateLocalDataService } from '../../../../services/CRUD/LocalDataService';

const EditLocal = () => {
  const { locationId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: '',
    name: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLocalDataService(locationId);
        if (response.Success && response.Success.length > 0) {
          const locationData = response.Success[0];
          setFormData({
            id: locationData.ID,
            name: locationData.NAME,
          });
        } else {
          console.error('Error al obtener los datos del local:', response.message);
        }
      } catch (error) {
        console.error('Error durante la solicitud:', error);
      }
    };

    fetchData();
  }, [locationId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await updateLocalDataService(formData);

    if (result.success) {
      console.log('Local actualizado:', result.message);
      navigate(`/views/local-review/`);
    } else {
      console.error('Error al actualizar el local:', result.message, result.errorData);
    }
  };

  return (
    <div className='form-container'>
      <div className="white-container size-stretch text-center">
        <h1>Editar Local</h1>
      </div>
      <br/>
      <form onSubmit={handleSubmit} className='white-container size-big' autoComplete="off">
        <label>
          Nombre del Local:
          <input className='form-input' type='text' name='name' value={formData.name} onChange={handleChange} required autoComplete="off" />
        </label>
        <button className='submit-button blue' type='submit'>
          Actualizar Local
        </button>
      </form>
    </div>
  );
};

export default EditLocal;