import React, { useState } from 'react';
import registerLocalService from '../../../../services/registerServices/registerLocalService';

const NewLocal = () => {
  const [formData, setFormData] = useState({
    name: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await registerLocalService.registerLocal({
        name: formData.name,
    });
    

    if (result.success) {
      console.log('Local registrado:', result.message);
    } else {
      console.error('Error al registrar el local:', result.message, result.errorData);
    }
  };

  return (
    <div className='form-container'>
      <div className="white-container size-stretch text-center">
        <h1>Registrar Nuevo Local</h1>
      </div>
      <br/>
      <form onSubmit={handleSubmit} className='white-container size-big'>
        <label>
          Nombre del Local:
          <input className='form-input' type='text' name='name' value={formData.name} onChange={handleChange} required />
        </label>
        <button className='submit-button blue' type='submit'>
          Registrar Local
        </button>
      </form>
    </div>
  );
};

export default NewLocal;