import { createContext, useContext, useReducer } from 'react';

const DataContext = createContext();

const initialState = {
  userData: null,
  permissions: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER_DATA':
      return { ...state, userData: action.payload };
    case 'SET_PERMISSIONS':
      return { ...state, permissions: action.payload };
    default:
      return state;
  }
};

export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};

export const useHasPrivileges = () => {
  const { state } = useData();
  const { permissions } = state;
  const currentPermissions = permissions || [];

  const HasPrivileges = (privileges) => {
    return privileges.some(privilege => 
      permissions.some(permission => permission.NAME === privilege)
    );
  };

  return HasPrivileges;
};
