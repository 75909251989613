import { BASE_URL } from '../apiConfig';

const getUserDataService = async (id) => {
    try {
        const url = `${BASE_URL}/get_user_data.php?id=${encodeURIComponent(id)}`;
        const response = await fetch(url);

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            return data;
        } else {
            console.error('Error en la solicitud HTTP:', response.statusText);
            return { error: `Error en la solicitud HTTP: ${response.statusText}` };
        }
    } catch (error) {
        console.error('Error durante la solicitud:', error);
        return { error: 'Error durante la solicitud' };
    }
};

const updateUserDataService = async (userData) => {
    try {
        // Construye la URL con los datos del usuario como parámetros de consulta
        const url = `${BASE_URL}/update_user_data.php?${getQueryString(userData)}`;

        const response = await fetch(url, { method: 'POST' });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            return data;
        } else {
            console.error('Error en la solicitud HTTP:', response.statusText);
            return { error: `Error en la solicitud HTTP: ${response.statusText}` };
        }
    } catch (error) {
        console.error('Error durante la solicitud:', error);
        return { error: 'Error durante la solicitud' };
    }
};

// Función para construir la cadena de consulta a partir de un objeto de datos
const getQueryString = (data) => {
    return Object.entries(data)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
};


const deleteUserDataService = async (userId) => {
    try {
        const url = `${BASE_URL}/delete_user.php?id=${encodeURIComponent(userId)}`;
        const response = await fetch(url);

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            return data;
        } else {
            console.error('Error en la solicitud HTTP:', response.statusText);
            return { error: `Error en la solicitud HTTP: ${response.statusText}` };
        }
    } catch (error) {
        console.error('Error durante la solicitud:', error);
        return { error: 'Error durante la solicitud' };
    }
};

export { getUserDataService, updateUserDataService, deleteUserDataService };
