import { createZipFile } from './ZipUtils';

export const downloadFiles = (files, fileName) => {
  createZipFile(files)
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};