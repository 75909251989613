import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/logo/tatan-logo-white.png';
import { useHasPrivileges  } from '../../../contexts/DataContext';
import './Sidebar.css';

const Sidebar = ({ menuActive, setMenuActive }) => {
  const [codesSubMenuActive, setCodesSubMenuActive] = useState(false);
  const [adminSubMenuActive, setAdminSubMenuActive] = useState(false);
  const [viewsSubMenuActive, setViewsSubMenuActive] = useState(false);
  const navigate = useNavigate();
  const hasPrivileges = useHasPrivileges();

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const toggleCodesSubMenu = () => {
    setCodesSubMenuActive(!codesSubMenuActive);
  };
 
  const toggleViewsSubMenu = () => {
    setViewsSubMenuActive(!viewsSubMenuActive);
  };

  const toggleAdminSubMenu = () => {
    setAdminSubMenuActive(!adminSubMenuActive);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setMenuActive(false); // Cerrar el menú después de la navegación
  };

  

  return (
    <aside className={`sidebar ${menuActive ? 'active' : ''}`}>
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" onClick={() => handleNavigation('/home/')} />
      </div>
      <nav className="nav-links">

        {hasPrivileges(['CODE VALID', 'CODE CONFIG', 'CODE TOURNAMENT']) && (
        <><button className="button-text" onClick={() => toggleCodesSubMenu()}>Códigos<div className={`menu-symbol ${codesSubMenuActive ? 'active' : ''}`}>{!codesSubMenuActive ? '►' : '▼'}</div></button>
        <div className={`sub-menu ${codesSubMenuActive ? 'active' : ''}`}>
            {hasPrivileges(['CODE VALID']) && ( <button className="button-text"  onClick={() => handleNavigation('/codes/valid/')}>Validación</button> )}
            {hasPrivileges(['CODE CONFIG']) && ( <button className="button-text" onClick={() => handleNavigation('/codes/config/')}>Configuración</button> )}
            {hasPrivileges(['CODE TOURNAMENT']) && ( <button className="button-text" onClick={() => handleNavigation('/codes/tournament/')}>Modo Torneo</button> )}
        </div></>
        )}

        {hasPrivileges(['READ LOCAL', 'READ MACHINE', 'READ USER']) && (
        <><button className="button-text" onClick={() => toggleViewsSubMenu()}>Vistas<div className={`menu-symbol ${viewsSubMenuActive ? 'active' : ''}`}>{!viewsSubMenuActive ? '►' : '▼'}</div></button>
        <div className={`sub-menu ${viewsSubMenuActive ? 'active' : ''}`}>
          {hasPrivileges(['READ LOCAL']) && ( <button className="button-text" onClick={() => handleNavigation('/views/local-review/')}>Locales</button> )}
          {hasPrivileges(['READ MACHINE']) && ( <button className="button-text" onClick={() => handleNavigation('/views/machine-review/')}>Máquinas</button> )}
          {hasPrivileges(['READ USER']) && ( <button className="button-text" onClick={() => handleNavigation('/views/users-review/')}>Usuarios</button> )}
        </div></>
        )}

        {hasPrivileges(['BUNDLE', 'CREATE LOCAL', 'CREATE USER']) && (
          <><button className="button-text" onClick={() => toggleAdminSubMenu()}> Panel Administración <div className={`menu-symbol ${adminSubMenuActive ? 'active' : ''}`}>{!adminSubMenuActive ? '►' : '▼'}</div></button>
          <div className={`sub-menu ${adminSubMenuActive ? 'active' : ''}`}>
            {hasPrivileges(['BUNDLE']) && ( <button className="button-text" onClick={() => handleNavigation('/admin-panel/new-bundles/')}> Nuevo Bundle </button> )}
            {hasPrivileges(['CREATE LOCAL']) && ( <button className="button-text" onClick={() => handleNavigation('/admin-panel/new-local/')}> Nuevo Local </button> )}
            {hasPrivileges(['CREATE USER']) && ( <button className="button-text" onClick={() => handleNavigation('/admin-panel/new-user/')}> Nuevo Usuario </button> )}
          </div></>
        )}
      </nav>
    </aside>
  );
};

export default Sidebar;
